// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-archive-case-studies-ai-js": () => import("./../../../src/pages/archive/case-studies/ai.js" /* webpackChunkName: "component---src-pages-archive-case-studies-ai-js" */),
  "component---src-pages-archive-gc-js": () => import("./../../../src/pages/archive/gc.js" /* webpackChunkName: "component---src-pages-archive-gc-js" */),
  "component---src-pages-archive-gcc-js": () => import("./../../../src/pages/archive/gcc.js" /* webpackChunkName: "component---src-pages-archive-gcc-js" */),
  "component---src-pages-archive-imco-js": () => import("./../../../src/pages/archive/imco.js" /* webpackChunkName: "component---src-pages-archive-imco-js" */),
  "component---src-pages-archive-levy-js": () => import("./../../../src/pages/archive/levy.js" /* webpackChunkName: "component---src-pages-archive-levy-js" */),
  "component---src-pages-archive-super-73-js": () => import("./../../../src/pages/archive/super73.js" /* webpackChunkName: "component---src-pages-archive-super-73-js" */),
  "component---src-pages-archive-variis-js": () => import("./../../../src/pages/archive/variis.js" /* webpackChunkName: "component---src-pages-archive-variis-js" */),
  "component---src-pages-archive-web-design-js": () => import("./../../../src/pages/archive/web-design.js" /* webpackChunkName: "component---src-pages-archive-web-design-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-team-js": () => import("./../../../src/pages/team.js" /* webpackChunkName: "component---src-pages-team-js" */),
  "component---src-pages-test-js": () => import("./../../../src/pages/test.js" /* webpackChunkName: "component---src-pages-test-js" */)
}

