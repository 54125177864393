import React, { useState } from "react";

export const GlobalContext = React.createContext();

export const GlobalProvider = ({ children }) => {
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [pageScrolled, setPageScrolled] = useState(false);
  const [pageScrollUp, setPageScrollUp] = useState(false);
  const [resetLottieLogo, setResetLottieLogo] = useState(false);
  const [preloaderLoaded, setPreloaderLoaded] = useState(false);
  const [preloaderComplete, setPreloaderComplete] = useState(false);
  const [cursor, setCursor] = useState({ active: false });
  const [footerCursor, setFooterCursor] = useState(false );
  const [contactCursor, setContactCursor] = useState(false );

  return (
    <GlobalContext.Provider
      value={{
        videoLoaded, setVideoLoaded,
        pageScrolled, setPageScrolled,
        pageScrollUp, setPageScrollUp,
        resetLottieLogo, setResetLottieLogo,
        preloaderLoaded, setPreloaderLoaded,
        preloaderComplete, setPreloaderComplete,
        cursor, setCursor,
        footerCursor, setFooterCursor,
        contactCursor, setContactCursor
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};
